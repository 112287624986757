import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { useRequestCounterpartyRatingRiskMutation } from 'services';
import { useToast } from '../../../Toast';
import { useForm } from 'react-hook-form';
import { QuotaCounterpartyViewModel, RatingRiskType } from 'schema';
import { useQueryClient } from 'react-query';
import { Button } from '../../../Button';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'grid',
      gridTemplateColumns: '50% 1fr 1fr',
      gridGap: theme.spacing(0, 2),
      fontSize: 14,
      fontWeight: 600,
      padding: theme.spacing(0),
      color: theme.palette.text.primary,
      alignItems: 'center',
      '&$high': {
        backgroundColor: alpha(theme.palette.error.main, 0.08),
      },
    },
    name: {
      padding: theme.spacing(2, 0, 2, 2),
      lineHeight: '20px',
      color: theme.palette.text.primary,
      borderLeft: `4px solid ${theme.palette.text.secondary}`,
      '&$high': {
        borderLeft: `4px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&$medium': {
        borderLeft: `4px solid ${theme.palette.attention.main}`,
        color: theme.palette.attention.main,
      },
      '&$low': {
        borderLeft: `4px solid ${theme.palette.success.main}`,
        color: theme.palette.text.primary,
      },
    },
    link: {
      color: theme.palette.text.primary,
    },
    risk: {
      fontSize: 12,
      fontWeight: 500,
    },
    high: {
      color: theme.palette.error.main,
    },
    medium: {
      color: theme.palette.attention.main,
    },
    low: {
      color: theme.palette.success.main,
    },
    none: {},
    subTitle: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    action: {
      textAlign: 'right',
      paddingRight: theme.spacing(2.5),
    },
  })
);

type RisksProps = {
  risks: QuotaCounterpartyViewModel[];
  quotaId: number;
};

export const Risks = (props: RisksProps) => {
  const { risks, quotaId } = props;
  return (
    <div>
      {risks.map((risk) => (
        <Risk {...risk} quotaId={quotaId} key={risk.inn} />
      ))}
    </div>
  );
};

export const Risk = (props: QuotaCounterpartyViewModel & { quotaId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { inn, name, canRequestRatingRisk, counterpartyType, totalRisk, quotaId } = props;

  const queryClient = useQueryClient();

  const { mutateAsync } = useRequestCounterpartyRatingRiskMutation(quotaId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('counterparties') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const { handleSubmit } = useForm<{ inn: string }>({
    mode: 'onBlur',
    defaultValues: {
      inn: inn,
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: { inn: string }) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <form onSubmit={onSubmit}>
      <div className={clsx(classes.row, classes[totalRisk])}>
        <div className={clsx(classes.name, classes[totalRisk])}>
          <Link to={`/counterparties/${inn}`} className={classes.link}>
            {name}{' '}
            <Typography variant={'body2'} component={'span'} className={classes.subTitle}>
              ({inn})
            </Typography>
          </Link>
          <br />
          <Typography variant={'body2'} className={classes.subTitle}>
            {counterpartyType === 'lessee' ? t('Lessee') : t('Dealer')}
          </Typography>
        </div>
        <div>
          <Typography variant={'body2'} component={'span'} className={classes.subTitle}>
            Уровень риска:
          </Typography>
          <Typography variant={'body1'} component={'span'} className={classes.risk}>
            <RatingTypeColored ratingType={totalRisk} />
          </Typography>
        </div>
        <div className={classes.action}>
          {totalRisk === RatingRiskType.none || !totalRisk ? (
            <Button variant="contained" type="submit" disabled={!canRequestRatingRisk}>
              {canRequestRatingRisk ? 'Запросить оценку' : 'Обрабатывается'}
            </Button>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export const RatingTypeColored = ({ ratingType }: { ratingType: RatingRiskType }) => {
  const classes = useStyles();
  return ratingType === RatingRiskType.none ? (
    <span className={classes.risk}> —</span>
  ) : ratingType === RatingRiskType.low ? (
    <span className={classes.low}> Низкий</span>
  ) : ratingType === RatingRiskType.medium ? (
    <span className={classes.medium}> Средний</span>
  ) : ratingType === RatingRiskType.high ? (
    <span className={classes.high}> Высокий</span>
  ) : (
    <span />
  );
};
